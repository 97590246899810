import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import LocalePt from '@angular/common/locales/pt';
registerLocaleData(LocalePt, 'pt');

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CountdownModule } from 'ngx-countdown';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { CadastroComponent } from './paginas/cadastro/cadastro.component';
import { LoginComponent } from './paginas/login/login.component';
import { IndexComponent } from './paginas/index/index.component';
import { HeaderComponent } from './includes/header/header.component';
import { PopupVideoComponent } from './includes/popup-video/popup-video.component';
import { HomeComponent } from './paginas/home/home.component';
import { PerfilComponent } from './includes/perfil/perfil.component';
import { FooterComponent } from './includes/footer/footer.component';
import { ExtratoComponent } from './paginas/extrato/extrato.component';
import { PrimeiroAcessoComponent } from './paginas/primeiro-acesso/primeiro-acesso.component';
import { ConfirmacaoComponent } from './paginas/confirmacao/confirmacao.component';
import { RegulamentomodalComponent } from './includes/regulamentomodal/regulamentomodal.component';
import { DescadastrarComponent } from './paginas/descadastrar/descadastrar.component';
import { FaqComponent } from './paginas/faq/faq.component';
import { ContagemRegressivaComponent } from './includes/contagem_regressiva/contagem_regressiva.component';
import { DescontosComponent } from './paginas/descontos/descontos.component';
import { RankingComponent } from './paginas/ranking/ranking.component';
import { LoaderComponent } from './includes/loader/loader.component';
import { LandingComponent } from './paginas/landing/landing.component';
import { LandingMobileComponent } from './paginas/landing-mobile/landing-mobile.component';
import { HeaderUserComponent } from './includes/header-user/header-user.component';
import { EnqueteComponent } from './paginas/enquete/enquete.component';
import { ExamplePdfViewerComponent } from './example-pdf-viewer/example-pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CardRankingExtratoComponent } from './includes/card-ranking-extrato/card-ranking-extrato.component';
import { FirstSlideComponent } from './includes/first-slide/first-slide.component';
import { SecondSlideComponent } from './includes/second-slide/second-slide.component';
import { ThirdSlideComponent } from './includes/third-slide/third-slide.component';
import { PlacarComponent } from './includes/placar/placar.component';
import { BlogComponent } from './paginas/blog/blog.component';
import { OngsComponent } from './paginas/ongs/ongs.component';
import { BoletosComponent } from './paginas/boletos/boletos.component';
import { PerfilPageComponent } from './paginas/perfil/perfil.component';
import { IndiqueComponent } from './paginas/indique/indique.component';
import { CadastroCpfComponent } from './includes/cadastro-cpf/cadastro-cpf.component';
import { DoarComponent } from './includes/doar/doar.component';
import { ModalComponent } from './includes/modal/modal.component';


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        PerfilComponent,
        HeaderComponent,
        IndexComponent,
        CadastroComponent,
        LoginComponent,
        PopupVideoComponent,
        FooterComponent,
        CardRankingExtratoComponent,
        ExtratoComponent,
        PrimeiroAcessoComponent,
        ConfirmacaoComponent,
        RegulamentomodalComponent,
        DescadastrarComponent,
        FaqComponent,
        FirstSlideComponent,
        SecondSlideComponent,
        ThirdSlideComponent,
        ContagemRegressivaComponent,
        DescontosComponent,
        RankingComponent,
        LoaderComponent,
        LandingComponent,
        LandingMobileComponent,
        HeaderUserComponent,
        EnqueteComponent,
        ExamplePdfViewerComponent,
        PlacarComponent,
        ExtratoComponent,
        BlogComponent,
        OngsComponent,
        BoletosComponent,
        PerfilPageComponent,
        IndiqueComponent,
        CadastroCpfComponent,
        DoarComponent,
        ModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        NgxUiLoaderModule,
        FontAwesomeModule,
        NgxMaskModule.forRoot(),
        CurrencyMaskModule,
        NgxUiLoaderModule,
        CountdownModule,
        FontAwesomeModule,
        AutocompleteLibModule,
        NgxExtendedPdfViewerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: false, //enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'pt-BR'},
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
