import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CadastroComponent } from './paginas/cadastro/cadastro.component';
import { HomeComponent } from './paginas/home/home.component';
import { IndexComponent } from './paginas/index/index.component';
import { LoginComponent } from './paginas/login/login.component';
import { PrimeiroAcessoComponent } from './paginas/primeiro-acesso/primeiro-acesso.component';
import { ConfirmacaoComponent } from './paginas/confirmacao/confirmacao.component';
import { DescadastrarComponent } from './paginas/descadastrar/descadastrar.component';
import { FaqComponent } from './paginas/faq/faq.component';
import { DescontosComponent } from './paginas/descontos/descontos.component';
import { RankingComponent } from './paginas/ranking/ranking.component';
import { LandingComponent } from './paginas/landing/landing.component';
import { LandingMobileComponent } from './paginas/landing-mobile/landing-mobile.component';
import { EnqueteComponent } from './paginas/enquete/enquete.component';
import { ExtratoComponent } from './paginas/extrato/extrato.component';
import { BlogComponent } from './paginas/blog/blog.component';
import { OngsComponent } from './paginas/ongs/ongs.component';
import { BoletosComponent } from './paginas/boletos/boletos.component';
import { PerfilPageComponent } from './paginas/perfil/perfil.component';
import { IndiqueComponent } from './paginas/indique/indique.component';

const routes: Routes = [
  {    path: 'assets',            pathMatch: 'prefix', redirectTo: 'assets'},
  {    path: 'admin',             pathMatch: 'prefix', redirectTo: 'admin'},
  {    path: 'folder/:id',        loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)  },
  {    path: '',                  component: LoginComponent,    pathMatch: 'full'  },
  {    path: 'login',             component: LoginComponent  },
  {    path: 'cadastro',          component: CadastroComponent  },
  {    path: 'cadastrar',         component: CadastroComponent  },
  {    path: 'indicacao/:id',     component: CadastroComponent  },
  {    path: 'home',              component: HomeComponent  },
  {    path: 'beneficios',        component: DescontosComponent},
  {    path: 'primeiro-acesso',   component: PrimeiroAcessoComponent},
  {    path: 'extrato',           component: ExtratoComponent},
  {    path: 'blog',              component: BlogComponent},
  {    path: 'ongs',              component: OngsComponent},
  {    path: 'boleto',            component: BoletosComponent},
  {    path: 'perfil',            component: PerfilPageComponent},
  {    path: 'indique',           component: IndiqueComponent},
  {    path: 'descontos',         component: DescontosComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
