import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  perfil;
  user_name;
  user_id;
  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  blogData: any[] = [];

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    this.perfil = localStorage.getItem('perfil');

    let foto_src = localStorage.getItem('foto_src');
    if (foto_src) {
      this.foto_src = foto_src;
    }

    this.getAPIPostData(); // Substituí a chamada para a nova função.
  }

  getAPIPostData() {
    const apiUrl = 'https://blog.primaveras.com.br/wp-json/wp/v2/posts?per_page=6&_embed';
  
    this.http.get(apiUrl).subscribe((data: any) => {
      data.forEach((item) => {
        const title = item.title.rendered || 'N/A';
        const link = item.link || 'N/A';
        const commentsLink = item.link || 'N/A';
        const description = this.extractDescription(item.content.rendered || 'N/A');
  
        // Obtenha a imagem em destaque (featured_media) do post
        const featuredMedia = item._embedded['wp:featuredmedia'];
        const imageUrl = featuredMedia && featuredMedia.length > 0 ? featuredMedia[0].source_url : 'N/A';
  
        const itemData = {
          title,
          link,
          commentsLink,
          description,
          imageUrl,
        };
  
        this.blogData.push(itemData);
      });
    });
  }
  

  getFeaturedImageURL(mediaId: number) {
    const apiUrl = `https://blog.primaveras.com.br/wp-json/wp/v2/media/${mediaId}`;

    return this.http.get(apiUrl).pipe(
      map((data: any) => data.source_url || 'N/A')
    );
  }

  extractDescription(fullDescription: string): string {
    const paragraphs = fullDescription.split('<p>'); // Divide o texto em parágrafos
    if (paragraphs.length >= 2) {
      const firstParagraph = paragraphs[1]; // Pega o segundo elemento (primeiro parágrafo)
      return `<p>${firstParagraph}`; // Adiciona a tag <p> novamente
    } else {
      return fullDescription;
    }
  }

  loadMorePosts() {
    const nextPage = this.blogData.length / 6 + 1; // Calcule a próxima página de posts
    const apiUrl = `https://blog.primaveras.com.br/wp-json/wp/v2/posts?per_page=6&page=${nextPage}&_embed`;
  
    this.http.get(apiUrl).subscribe((data: any) => {
      data.forEach((item) => {
        const title = item.title.rendered || 'N/A';
        const link = item.link || 'N/A';
        const commentsLink = item.link || 'N/A';
        const description = this.extractDescription(item.content.rendered || 'N/A');
  
        // Obtenha a imagem em destaque (featured_media) do post
        const featuredMedia = item._embedded['wp:featuredmedia'];
        const imageUrl = featuredMedia && featuredMedia.length > 0 ? featuredMedia[0].source_url : 'N/A';
  
        const itemData = {
          title,
          link,
          commentsLink,
          description,
          imageUrl,
        };
  
        this.blogData.push(itemData);
      });
    });
  }
  

  redirecionarComConfirmacao(post: string) {
    if (!window.confirm('Tem certeza de que deseja ir para esta página?')) {
      event.preventDefault(); // Evitar o redirecionamento
    }
  }
}
