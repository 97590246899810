import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {

  @Input() modalTitle: string;
  @Input() modalSubtitle: string;
  @Input() modalContent: string;
  @Input() buttonContent: string;
  @Input() showHeading: boolean = true;
  @Input() btnLink: boolean = false;
  @Input() btnNav: boolean = false;
  @Input() buttonNavLink: string;
  @Input() buttonLink: string;
  @Output() closeModalEvent = new EventEmitter<void>();

  perfil;
  user_name;
  user_id;
  constructor() { }

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
  }

  closeModal() {
    this.closeModalEvent.emit();
  }
  
}
