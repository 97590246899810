import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

interface AccordionItem {
  title: string;
  content: string;
  expanded: boolean;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {

  public accordionItems: AccordionItem[] = [
    {
      title: 'O que é o Iclub',
      content: 'Texto explicando o que é o Iclub.',
      expanded: false
    },
    {
      title: 'Como participar?',
      content: 'Instruções sobre como participar do Iclub.',
      expanded: false
    },
    {
      title: 'O que são descontos nos parceiros',
      content: 'Informações sobre os descontos oferecidos pelos parceiros do Iclub.',
      expanded: false
    },
    {
      title: 'Meu comentário ou publicação foi excluído e agora?',
      content: 'O que fazer se um comentário ou publicação for excluído no Iclub.',
      expanded: false
    }
  ];

  

  user_id;
  user_name;
  
  perfil;
  public items:any = [];

  @Input()
  limite: string;

  regulamento = false;
  is_desktop = false;
  constructor(private router:Router, private http: HttpClient, private route:ActivatedRoute) {
    
   }
  
  ngOnInit() {
    
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    this.perfil = localStorage.getItem('perfil');

    this.obter_faq();


    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }
  }


  toggleItem(item: AccordionItem): void {
    item.expanded = !item.expanded;
  }

  obter_faq(){
    this.http.get<any>(environment.API_URL.concat('faq/index/'+this.perfil)).subscribe( data =>{
        if(this.limite){
            this.items = data.slice(0,this.limite);
        } else {
            this.items = data;
        }
    });
}
}
