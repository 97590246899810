import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boletos',
  templateUrl: './boletos.component.html',
  styleUrls: ['./boletos.component.scss'],
})
export class BoletosComponent implements OnInit {


  perfil;
  user_name;
  user_id;
  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

  constructor() { }

  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    this.perfil = localStorage.getItem('perfil');

    let foto_src = localStorage.getItem('foto_src');
    if(foto_src){
      this.foto_src = foto_src;
    }
  }
}
