import { Component, Injectable, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
}) @Injectable()

export class HeaderComponent implements OnInit {

  foto_src;
  user_id;
  user_name;

  constructor() {

  }

  ngOnInit(): void {
    let foto_src = localStorage.getItem('foto_src');
    if(foto_src){
      this.foto_src = foto_src;
    }

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
  }


}
