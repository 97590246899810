export class Cadastro {

    public id_indicador:string;
    public id_contrato:string;
    public cpf:string = "";
    public tipo:number = 0;
    public nome:string=""; 
    public perfil:number=0; 
    //public sobrenome:string="";
    public email:string="";
    //public email_c:string="";
    public celular:string="";
    public aniversario:string="";
    public sexo:string="";
    public cep:string="";
    public rua:string="";
    public numero:string="";
    public complemento:string=""; 
    public bairro:string="";
    public estado:string="";
    public cidade:string=""; 
    public senha:string="";
    public senha_c:string=""; 
    public preferencias="";
    public aceito_info:boolean = false;
    public aceito_term:boolean;
    public token_cadastro:string="";
    public confirmou_cadastro:string="";
    public img_url:any = '';
}