import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-landing-mobile',
  templateUrl: './landing-mobile.component.html',
  styleUrls: ['./landing-mobile.component.scss'],
})
export class LandingMobileComponent implements OnInit {
  public regulamento = false;

  constructor(private router:Router) { }

  ngOnInit() {
    if ($(window).width() >= 780){
      this.router.navigate(['/landing']);
    }

    $(window).on('resize', () => {
      if ($(window).width() > 780){
        this.router.navigate(['/landing']);
      }
    }); 
  }

}
