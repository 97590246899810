import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-second-slide',
  templateUrl: './second-slide.component.html',
  styleUrls: ['./second-slide.component.scss'],
})
export class SecondSlideComponent implements OnInit {


  shareOnFacebook() {
    let url = window.location.origin;

    const customSlideUrl = url+'/admin/api/campanhas_primaveras/slide/2';
    // Verifica se o aplicativo do Facebook está instalado no dispositivo do usuário
    const isFacebookAppInstalled = window.navigator.userAgent.includes("FBAN") || window.navigator.userAgent.includes("FBAV");
    
    if (isFacebookAppInstalled) {
        // Se o aplicativo do Facebook estiver instalado, abre o link no aplicativo
        const facebookAppUrl = `fb://facewebmodal/f?href=${encodeURIComponent(customSlideUrl)}`;
        window.open(facebookAppUrl, '_blank');
    } else {
        // Se o aplicativo do Facebook não estiver instalado, abre o link no navegador
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${customSlideUrl}`;
        window.open(facebookShareUrl, '_blank');
    }
}

shareOnWhatsapp() {
  let url = window.location.origin;

  const customSlideUrl = url+'/admin/api/campanhas_primaveras/slide/2';
  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${customSlideUrl}`;
  
  window.open(whatsappShareUrl, '_blank');
}


  
  constructor() { }

  ngOnInit() {}

}
