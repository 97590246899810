import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card-ranking-extrato',
  templateUrl: './card-ranking-extrato.component.html',
  styleUrls: ['./card-ranking-extrato.component.scss'],
})
export class CardRankingExtratoComponent implements OnInit {

  fakeApiData = {
    userRank: 10,
    userPoints: 280,
    userCoins: 250,
    totalParcels: 12,
    paidParcels: 9,
  };


  user_id;
  regulamento = false;
  @Input() moedas;
  @Input() pontos;

  ranking = [];
  posicao = 0;
  indicacoes = 0;

  isOpen: boolean = false;

  progressBarId: string;

  constructor(private http: HttpClient, private route: Router) {

    const percentagePaid = Math.floor((12 / 12) * 100);
    switch (true) {
      case percentagePaid === 0:
        this.progressBarId = 'zero-bar';
        break;
      case percentagePaid < 33:
        this.progressBarId = 'one-bar';
        break;
      case percentagePaid < 48:
        this.progressBarId = 'two-bar';
        break;
      case percentagePaid <= 51 && percentagePaid >= 49:
        this.progressBarId = 'three-bar';
        break;
      case percentagePaid > 50 && percentagePaid < 80:
        this.progressBarId = 'four-bar';
        break;
      case percentagePaid === 100:
        this.progressBarId = 'full-bar';
        break;
      default:
        this.progressBarId = 'five-bar';
    }
  }

  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    let active_url = this.route.url;
    if (!active_url.includes('home')) {
      this.obter_dados();
    }

    this.obter_ranking();
    this.obter_indicacoes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if (changes.pontos) {
      this.obter_ranking();
    }

  }



  obter_dados() {
    this.http.get<any>(environment.API_URL.concat('pontos/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = data.pontos;
      this.moedas = data.moedas;
    });
  }

  checkAndCloseOrRedirect() {
    if (this.route.url === '/home') {
      this.isOpen = false;
    } else {
      this.route.navigate(['/home']);
      setTimeout(() => {
        this.isOpen = false;
      }, 1000);

    }
  }

  obter_ranking() {
    let url = 'participantes/obter_ranking/1000/0/'+this.user_id;

    this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
      this.ranking = data.ranking.slice(0, 5);
      if(data.posicao){
        this.posicao = data.posicao;
      }
      else{
        this.posicao = data.ranking.length;
      }
    });
  }

  abrirLinkNoWhatsApp() {
    if (window.confirm('Atenção, você será redirecionado para o WhatsApp.')) {
      var texto = "Desejo fazer um upgrade no meu plano Primaveras";
      var link = "https://api.whatsapp.com/send?phone=551124048900&text=" + encodeURIComponent(texto);
      window.open(link, "_blank");
    }
  }

  obter_indicacoes() {
    let url = 'cadastro/obter_indicados/' + this.user_id;

    this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
      if (data.status == 1) {
        this.indicacoes = data.indicados.filter( i => { return i.liberado == 1 }).length;
      }
    });
  }

  abrir_regulamento() {
    this.regulamento = true;
  }


}
